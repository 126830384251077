<template>
  <div id="coin-flip-cont">
    <div>
      <div class="signCoin">
        <span class="fast-flickerCoin">C</span>oin
        <span class="flickerCoin">F</span>lip
      </div>
    </div>
    <div>
      <div id="coin" class="coin" :key="coinKey">
        <div class="front"></div>
        <div class="back"></div>
      </div>
    </div>
    <div class="einsatzArea">
      <label class="einsatz">Einsatz: </label>
      <b-form-input
        type="number"
        class="einsatz borderEinsatz"
        v-model="einsatz"
      ></b-form-input>
      <b-form-group
        style="width: 80%; margin: 0 auto"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="btn-radios-1"
          v-model="selected"
          :disabled="disabledBool"
          :options="options"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default"
          buttons
        ></b-form-radio-group>

        <b-button
          style="float: right"
          @click="onStartGameClick"
          :disabled="disabledBool"
          >Spielen</b-button
        >
      </b-form-group>
    </div>
    <div class="neonsCoin">
      <em
        >Klicke unten, um zu spielen. <br />
        Wette auf Kopf oder Zahl!
      </em>
    </div>
    <br />
    <b-modal
      id="noMoneyModal"
      centered
      ok-title="Weiter"
      body-bg-variant="info"
      body-text-variant="dark"
      hide-footer
      hide-header
      ><div style="margin: 40px; font-size: 1.5rem">
        <center>
          <em
            >Achtung<br />
            Sie müssen erst Geld aufladen, damit Sie spielen können.</em
          >
        </center>
      </div>
      <div>
        <center>
          <b-button
            style="
              margin-bottom: 20px;
              padding: 10px 20px 10px 20px;
              font-size: 1.3rem;
            "
            variant="dark"
            @click="closeNoMoneyModal"
          >
            Verstanden
          </b-button>
        </center>
      </div>
    </b-modal>
    <b-modal
      id="winningModal"
      centered
      ok-title="Weiter"
      body-bg-variant="success"
      body-text-variant="dark"
      hide-footer
      hide-header
      ><div style="margin: 40px; font-size: 1.5rem">
        <center>
          <em
            >Glückwunsch!<br />
            Sie haben {{ wonMoney }}€ gewonnen!</em
          >
          <br />
          <br />
          Neuer Guthabenstand: {{ balance }}€
        </center>
      </div>
      <div>
        <center>
          <b-button
            style="
              margin-bottom: 20px;
              padding: 10px 20px 10px 20px;
              font-size: 1.3rem;
            "
            variant="dark"
            @click="closeWinningModal"
          >
            Weiter
          </b-button>
        </center>
      </div>
    </b-modal>
    <b-modal
      id="loosingModal"
      centered
      ok-title="Weiter"
      body-bg-variant="info"
      body-text-variant="dark"
      hide-footer
      hide-header
      ><div style="margin: 40px; font-size: 1.5rem">
        <center>
          <em
            >Schade...<br />
            Leider haben Sie kein Geld gewonnen - vielleicht nächstes Mal!</em
          >
          <br />
          <br />
          Neuer Guthabenstand: {{ balance }}€
        </center>
      </div>
      <div>
        <center>
          <b-button
            style="
              margin-bottom: 20px;
              padding: 10px 20px 10px 20px;
              font-size: 1.3rem;
            "
            variant="dark"
            @click="closeLoosingModal"
          >
            Weiter
          </b-button>
        </center>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DataService from "../services/data.service";
export default {
  components: {},
  data() {
    return {
      einsatz: 5,
      spinArrayHead: ["animation900"],
      spinArrayNumber: ["animation1080"],
      coinKey: 0,
      wonMoney: 0,
      balance: 0,
      disabledBool: false,
      selected: "head",
      options: [
        { text: "Kopf", value: "head" },
        { text: "Zahl", value: "number" },
      ],
      currentUser: null,
      response: null,
    };
  },

  methods: {
    onStartGameClick() {
      this.$data.currentUser = DataService.getLoggedInUser();

      this.getGameResult();
      this.$data.disabledBool = true;
    },

    getGameResult() {
      let bet_face = this.getBet();
      let data = {
        userID: this.currentUser.id,
        bet_face: bet_face,
        einsatz: this.einsatz,
      };
      DataService.playCoinflip(data).then((response) => {
        if (response.success) {
          this.$root.$emit("navBar", response.balance_before - this.einsatz);
        }
        this.$data.response = response;
        console.log(response);
        this.setAnimation(), 300;
      });
    },

    getBet() {
      // head returns false, face returns true
      if (this.$data.selected === "head") {
        return false;
      } else {
        return true;
      }
    },

    setAnimation() {
      let element = document.getElementById("coin");
      element.className = "";
      let randomSpin = this.getSpin();
      element.classList.add(randomSpin);
      setTimeout(this.showResult, 3100);
    },

    getSpin() {
      if (this.$data.selected === "head" && this.$data.response.win === true) {
        // betted "head" and won
        return this.setHeadAnimation();
      } else if (
        this.$data.selected === "number" &&
        this.$data.response.win === true
      ) {
        // betted "number" and won
        return this.setNumberAnimation();
      } else {
        // lost
        if (this.$data.selected === "head") {
          return this.setNumberAnimation();
        } else {
          return this.setHeadAnimation();
        }
      }
    },

    setHeadAnimation() {
      let spin =
        this.$data.spinArrayHead[
          Math.floor(Math.random() * this.$data.spinArrayHead.length)
        ];
      return spin;
    },

    setNumberAnimation() {
      let spin =
        this.$data.spinArrayNumber[
          Math.floor(Math.random() * this.$data.spinArrayNumber.length)
        ];
      return spin;
    },

    showResult() {
      if (this.$data.response.success === true) {
        if (this.$data.response.win === true) {
          this.$data.wonMoney = this.einsatz * 2;
          this.proceedAsWin();
        } else {
          this.proceedAsLoose();
        }
      } else {
        this.proceedAsNoMoney();
      }
      this.balance = this.response.balance_after;
      this.$root.$emit("navBar", this.response.balance_after);
      setTimeout(this.resetAll, 2000);
    },

    proceedAsLoose() {
      this.$bvModal.show("loosingModal");
    },

    proceedAsNoMoney() {
      this.$bvModal.show("noMoneyModal");
    },

    closeNoMoneyModal() {
      this.$bvModal.hide("noMoneyModal");
    },

    proceedAsWin() {
      this.$bvModal.show("winningModal");
    },

    resetAll() {
      this.coinKey++;
      this.$data.disabledBool = false;
    },

    closeWinningModal() {
      this.$bvModal.hide("winningModal");
    },

    closeLoosingModal() {
      this.$bvModal.hide("loosingModal");
    },
  },
  created() {
    if (!DataService.checkLoggedIn()) {
      this.$router.push("/Login");
    }
  },
};
</script>

<style scoped>
#loosingModal,
#winningModal,
#noMoneyModal {
  z-index: 9999;
}

.einsatz {
  width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.einsatzArea {
  margin-top: 370px !important;
  background-color: rgb(46, 46, 46);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: block;
  margin: 0 auto;
  color: white;
  width: 25%;
  padding: 8px;
  opacity: 0.9;
}

.coin {
  width: 35%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: block !important;
  margin-top: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  text-shadow: 0 0 0.6rem #575757, 0 0 1.5rem #575757,
    -0.2rem 0.1rem 1rem #575757, 0.2rem 0.1rem 1rem #575757,
    0 -0.5rem 2rem #575757, 0 0.5rem 3rem #575757;
  align-content: center;
  align-items: center;
  align-self: center;
}

#coin {
  position: absolute;
  width: 300px;
  transform-style: preserve-3d;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 35px;
}

#coin .front,
#coin .back {
  position: absolute;
  width: 300px;
  height: 300px;
}

#coin .front {
  transform: translateZ(1px);
  border-radius: 50%;
  background-image: url("https://i.colnect.net/f/3662/185/2-Dinara.jpg");
}

#coin .back {
  transform: translateZ(-1px) rotateY(180deg);
  border-radius: 50%;
  background-image: url("https://images-eu.ssl-images-amazon.com/images/I/71dg9J4CtvL.__AC_SX300_SY300_QL70_ML2_.jpg");
}

#coin.animation900 {
  -webkit-animation: rotate900 3s linear forwards;
  animation: rotate900 3s linear forwards;
}

@-webkit-keyframes rotate900 {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(900deg);
    -moz-transform: rotateY(900deg);
    transform: rotateY(900deg);
  }
}

@keyframes rotate900 {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(900deg);
    -moz-transform: rotateY(900deg);
    transform: rotateY(900deg);
  }
}

#coin.animation1080 {
  -webkit-animation: rotate1080 3s linear forwards;
  animation: rotate1080 3s linear forwards;
}

@-webkit-keyframes rotate1080 {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(1080deg);
    -moz-transform: rotateY(1080deg);
    transform: rotateY(1080deg);
  }
}

@keyframes rotate1080 {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(1080deg);
    -moz-transform: rotateY(1080deg);
    transform: rotateY(1080deg);
  }
}

.neonsCoin {
  margin-top: 20px;
  font-size: 1.7rem;
  text-align: center;
  font-weight: bold;
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    color: #fff;
    text-shadow: 0 0 10px #0f6d68, 0 0 20px #0f6d68, 0 0 30px #0f6d68,
      0 0 40px #0f6d68, 0 0 50px #0f6d68, 0 0 60px #0f6d68, 0 0 70px #0f6d68,
      0 0 90px #0f6d68;
  }

  to {
    color: grey;
    text-shadow: 0 0 20px #0f6d68, 0 0 30px #0f6d68, 0 0 40px #0f6d68,
      0 0 50px #0f6d68, 0 0 60px #0f6d68, 0 0 70px #0f6d68, 0 0 80px #0f6d68,
      0 1 90px #0f6d68;
  }
}

.signCoin {
  display: block;
  margin: 0 auto;
  width: fit-content;
  background-image: radial-gradient(
    ellipse 50% 35% at 50% 50%,
    #6b1839,
    transparent
  );
  letter-spacing: 2;
  font-family: "Clip";
  text-transform: uppercase;
  font-size: 4.5em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #8cc6e5, 0 0 1.5rem #0f66e9,
    -0.2rem 0.1rem 1rem #0f66e9, 0.2rem 0.1rem 1rem #0f66e9,
    0 -0.5rem 2rem #041c84, 0 0.5rem 3rem #041c84;
  animation: shine 2s forwards, flicker 3s infinite;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #8cc6e5, 0 0 1.5rem #0f66e9,
      -0.2rem 0.1rem 1rem #0f66e9, 0.2rem 0.1rem 1rem #0f66e9,
      0 -0.5rem 2rem #041c84, 0 0.5rem 3rem #041c84;
  }
  28%,
  33% {
    color: #0f66e9;
    text-shadow: none;
  }
  82%,
  97% {
    color: #041c84;
    text-shadow: none;
  }
}

.flickerCoin {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flickerCoin {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #8cc6e5, 0 0 1.5rem #0f66e9,
      -0.2rem 0.1rem 1rem #0f66e9, 0.2rem 0.1rem 1rem #0f66e9,
      0 -0.5rem 2rem #041c84, 0 0.5rem 3rem #041c84;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
</style>
